// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["lxUa904U4"];

const variantClassNames = {lxUa904U4: "framer-v-1pko2dk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "lxUa904U4", link: R4F3yJLoa, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "lxUa904U4", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-fXMsU", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<Link href={R4F3yJLoa} openInNewTab={false} smoothScroll><Image {...restProps} as={"a"} background={{alt: "sparetime-intro-sona-design", fit: "fit", intrinsicHeight: 1060, intrinsicWidth: 860, pixelHeight: 1060, pixelWidth: 860, src: new URL("assets/jqo1BO9erCPslvD0EHwkW1qQbJw.jpeg", import.meta.url).href, srcSet: `${new URL("assets/512/jqo1BO9erCPslvD0EHwkW1qQbJw.jpeg", import.meta.url).href} 415w, ${new URL("assets/1024/jqo1BO9erCPslvD0EHwkW1qQbJw.jpeg", import.meta.url).href} 830w, ${new URL("assets/jqo1BO9erCPslvD0EHwkW1qQbJw.jpeg", import.meta.url).href} 860w`}} className={`${cx("framer-1pko2dk", className)} framer-1xt841w`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"lxUa904U4"} ref={ref} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} transition={transition}/></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-fXMsU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fXMsU * { box-sizing: border-box; }", ".framer-fXMsU .framer-1xt841w { display: block; }", ".framer-fXMsU .framer-1pko2dk { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 773px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 1080px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-fXMsU .framer-1pko2dk { gap: 0px; } .framer-fXMsU .framer-1pko2dk > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-fXMsU .framer-1pko2dk > :first-child { margin-left: 0px; } .framer-fXMsU .framer-1pko2dk > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 773
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"R4F3yJLoa":"link"}
 */
const FramerVnmlaJzdC: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerVnmlaJzdC;

FramerVnmlaJzdC.displayName = "Sparetime register";

FramerVnmlaJzdC.defaultProps = {height: 773, width: 1080};

addPropertyControls(FramerVnmlaJzdC, {R4F3yJLoa: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerVnmlaJzdC, [])